import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import TicketContext from "./Context/TicketContext";
import AuthProvider from "./Context/AuthProvider";
import { initFacebookPixel } from './utils/facebookPixel';
initFacebookPixel();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <AuthProvider>
      <TicketContext>
        <Router>
          <App />
        </Router>
      </TicketContext>
    </AuthProvider>
  </>
);
