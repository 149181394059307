import {
  Button,
  Container,
  Form,
  Modal,
  ToastContainer,
} from "react-bootstrap";
import SecondHeader from "../../Components/Header/SecondHeader";
import "./payment.css";
import { useContext, useEffect, useState } from "react";
import { Axios } from "../../Api/axios";
import { baseURL, Pay, PaymentMethods, PayMobMethod , QNBMPGS , UPG } from "../../Api/Api";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import PaymentBtn from "../../Components/PaymentBtn/PaymentBtn";
import { Ticket } from "../../Context/TicketContext";
import Footer from "../../Components/Footer/Footer";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";
import { toast } from "react-toastify";
import { PaymentJson } from "./PaymentJson";

export default function Payment() {
  // States
  const [payments, setPayments] = useState([]);

  const [CircleLoadingIcon, setCircleLoadingIcon] = useState(false);
  const [tickets, setTickts] = useState([]);
  const [lastPrice, setLastPrice] = useState("");
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [payMobMethod, setPayMob] = useState(false);
  const [qnbMPGSMethod, setQNBMPGS] = useState(false);
  const [payMethodShow, setPayMethodShow] = useState("");
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [mobile, setMobile] = useState("");
  const [showPayBtn, setShowPayBtn] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [initiatedCheckout, setInitiatedCheckout] = useState(false);
  const [loadedHostedCheckout, setLoadedHostedCheckout] = useState(false);
  const [lightboxLoaded, setLightboxLoaded] = useState(false);
  // Nav
  const nav = useNavigate();
  const GetTicket = useContext(Ticket);
  
  // Hanldes

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    console.log(GetTicket);
    if (GetTicket.ticket.price === 0 || !GetTicket.ticket.price)
      nav("/profile");
    setTickts(localStorage.getItem("tickets"));
    setLastPrice(GetTicket.ticket.price);
    Axios.get(`${baseURL}/${PaymentMethods}`)
      .then((res) => {
        if (res.data.data === "token not valid") {
          nav("/login");
        } else {
          setPayments(res.data);
        }
      })
      .finally(() => setLoading(false));
  }, []);
    useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://qnbalahli.gateway.mastercard.com/static/checkout/checkout.min.js';
    script.async = true;

    script.onload = () => {
      setLoadedHostedCheckout(true);
      console.log('HostedCheckout script loaded successfully.');

    };
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
}, []);
useEffect(() => {
  const script = document.createElement('script');
  // script.src = 'https://upgstaging.egyptianbanks.com:3006/js/Lightbox.js';
  // script.src = 'https://upgstaglightbox.egyptianbanks.com/js/Lightbox.js';
  // script.src = 'https://upg.egyptianbanks.com:2008/INVCHOST/js/Lightbox.js';
  script.src = 'https://upglightbox.egyptianbanks.com/INVCHOST/js/Lightbox.js';

  script.async = true;

  script.onload = () => {
    console.log('Lightbox script loaded successfully.');
    setLightboxLoaded(true);

  };
  document.body.appendChild(script);
  
  return () => {
    document.body.removeChild(script);
  };
}, []);

    async function initiateUPGLightboxModal () {
      // Configure UPG Lightbox here
      try {
        console.log('clicked');
        const response = await Axios.post(`/${UPG}`, {
          payment_method: 8,
          tickets: tickets,
        });
        if (lightboxLoaded) {
          let reurl=response.data.returnurl;
          let hash=response.data.hash;
          Lightbox.Checkout.configure = {
        MID: response.data.mid,
        TID: response.data.tid,
        OrderId: '',
        MerchantReference: response.data.order,
        AmountTrxn: response.data.amount, 
        // AmountTrxn: lastPrice * 100, 
        //paymentMethodFromLightBox: response.data.paymentMethodFromLightBox, 
        paymentMethodFromLightBox: 2, 
        SecureHash: response.data.hash,
        TrxDateTime: response.data.trxDateTime,
        //ExpirationDateTime: '202401241329103152',
        ReturnUrl: response.data.returnurl,
        completeCallback: function (data) {
          let loc=reurl+'?secureHash='+hash;
          window.location.href=loc;
          toast.success("تمت عملية الدفع بنجاح");
          console.log('Payment Successful', data);
          //nav('/profile');
          // window.location.href=response.data.returnurl;
          
        },
        errorCallback: function (data) {
          toast.error("فشلت عملية الدفع");
          nav('/profile');
          console.log('Payment Error', data);
        },
        cancelCallback: function () {
          toast.error("تم إلغاء عملية الدفع");
          console.log('Payment Cancelled');
        }
      };
      if (response.data.show=='lightbox') {
      Lightbox.Checkout.showLightbox();
    }
    else if (response.data.show=='page') {
      Lightbox.Checkout.showPaymentPage();
    }
    }
  else {
    console.error('Lightbox script is not yet loaded.');
  }
  
      } catch (error) {
        console.error("Error initiating hosted checkout:", error);
      } finally {
        setLoadedHostedCheckout(false);
      }
      
    };
    const [clicked, setClicked] = useState(false);

  const onClickHandler = () => {
    if (!clicked) {
      setClicked(true);

      findData?.openModal && handleShow();
      setPayMethodShow(Number(pay.id));
      setShowPayBtn(findData?.showPayBtn);
      findData?.payMobMethod && HandlePayMob();

      if (Number(pay.id) === 7) {
        initiateHostedCheckout(); 
        setTimeout(startPaymentProcess, 2000);
      }

      if (Number(pay.id) === 8) {
        setTimeout({initiateUPGLightboxModal}, 2000);
      }

      // Reset the clicked state after a delay (e.g., 3 seconds)
      setTimeout(() => {
        setClicked(false);
      }, 3000); // Adjust the delay as per your requirement
    }
  };
  const startPaymentProcess = () => {
    Checkout.showEmbeddedPage('#embedded-container');
    //Checkout.showPaymentPage();
  };

  // Handle Send Data
  async function HandleSendData(e) {
    setCircleLoadingIcon(true);
    e.preventDefault();
    const res = await Axios.post(`/${Pay}`, {
      payment_method: 1,
      tickets: tickets,
    });
    setCircleLoadingIcon(false);
    if (res.data.message === "رصيد المحفظة أقل من مبلغ التذاكر المحجوزة") {
      toast.error("رصيد المحفظة أقل من مبلغ التذاكر المحجوزة");
    } else {
      nav("/profile");
    }
  }
  // HandlePayMob(Mobile) Method

  async function HandlePayMobmobile() {
    try {
      setLoadingPayment(true);
      const res = await Axios.post(`/${PayMobMethod}`, {
        payment_method: 6,
        mobile: mobile,
        tickets: tickets,
      });
      const redirectUrl = res.data.redirect_url;
      window.location.href = redirectUrl;
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingPayment(false);
    }
  }

  // Handle PayMob Method
  async function HandlePayMob() {
    try {
      setLoadingPayment(true);
      const tickets = localStorage.getItem("tickets");
      const res = await Axios.post(`/${PayMobMethod}`, {
        payment_method: 5,
        tickets: tickets,
      });
      const iframeId = res.data.iframeId;
      const paymentToken = res.data.payment_key.token;
      setPayMob(
        `https://accept.paymobsolutions.com/api/acceptance/iframes/${iframeId}?payment_token=${paymentToken}`
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingPayment(false);
    }
  };
  async function initiateHostedCheckout() {
  
    try {


      const response = await Axios.post(`/${QNBMPGS}`, {
        payment_method: 7,
        tickets: tickets,
      });
      setSessionId(response.data.sessionId);
      if(loadedHostedCheckout){
      window.Checkout.configure({
        "session": {
          id: sessionId
        },
      });

    }
    else {
      console.error('HostedCheckout script is not yet loaded.');
    }
    } catch (error) {
      console.error("Error initiating hosted checkout:", error);
    } finally {
      setLoadingHostedCheckout(false);
    }
  };
    const paymentMethodsShow = payments.map((pay) => {
    const findData = PaymentJson.find((data) => data.id === Number(pay.id));

      
      
    
    return (
      <div
        onClick={() => {
          if (!clicked) {
            setClicked(true);
      
            findData?.openModal && handleShow();
            setPayMethodShow(Number(pay.id));
            setShowPayBtn(findData?.showPayBtn);
            findData?.payMobMethod && HandlePayMob();
      
            if (Number(pay.id) === 7) {
              initiateHostedCheckout(); 
              setTimeout(startPaymentProcess, 2000);
            };
      
            if (Number(pay.id) === 8) {
              setTimeout(initiateUPGLightboxModal, 2000);
            };
      
            // Reset the clicked state after a delay (e.g., 3 seconds)
            setTimeout(() => {
              setClicked(false);
            }, 15000); // Adjust the delay as per your requirement
          };
                
        }}
        className={`d-flex align-items-center ${
          pay.balance && "justify-content-between"
        } mt-4 text-decoration-none gap-4 flex-wrap px-md-5 py-4 px-3 rounded shadow row-gap-3 bg-custom-primary payment `}
      >
        <Form.Check
          type={"radio"}
          id={pay.id}
          label={findData?.name}
          name="radio"
          className="mb-0 text-white"
        />
        <img src={findData?.img} alt="card" width={findData?.width} />
        {pay.balance && (
          <h5 className="text-white m-0 text-end col-md-8 col-12">
            رصيد المحفظة : {pay.balance} ج.م
          </h5>
        )}
      </div>
    );
  });


  return (
    <>
      <ToastContainer
        containerId="4"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="p-0" >
          {loadingPayment && <LoadingSpinner />}
          {payMethodShow === 5 ? (
            <iframe
              width={"100%"}
              height={"800px"}
              title="payMobMethod"
              className="iframe"
              src={payMobMethod}
            />
          ) : payMethodShow === 7 ? (
            <div
            id={"embedded-container"}
            width={"100%"}
            height={"800px"}
            title="QNB"
            className="iframe"
          />
          ) : payMethodShow === 8 ? (
            <iframe
          style={{ width: '100%', height: '650px', border: 'none' }}
          allow="payment"
        ></iframe>
          ) : payMethodShow === 6 ? (
            <>
              <Form.Group
                dir="rtl"
                className="mb-3 p-5"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ fontSize: "25px" }}>
                  رقم الموبايل المربوط به المحفظة
                </Form.Label>
                <Form.Control
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  type="number"
                  style={{ fontSize: "22px" }}
                  placeholder="مثال: 0111225344 "
                />
                <Button
                  onClick={HandlePayMobmobile}
                  className="mt-3 w-100"
                  variant="primary"
                >
                  إرسال
                </Button>
              </Form.Group>
              <div className="d-flex align-items-center justify-content-center flex-wrap p-3">
                <div className="col-sm-6 col-md-4 col-12 text-center">
                  <img
                    className="m-1"
                    src={require("../../Assets/images/016.jpg")}
                    alt="icon"
                  />{" "}
                </div>{" "}
                <div className="col-sm-6 col-md-4 col-12 text-center">
                  <img
                    className="m-1"
                    src={require("../../Assets/images/005.jpg")}
                    alt="icon"
                  />{" "}
                </div>{" "}
                <div className="col-sm-6 col-md-4 col-12 text-center">
                  <img
                    className="m-1"
                    src={require("../../Assets/images/012.jpg")}
                    alt="icon"
                  />{" "}
                </div>{" "}
                <div className="col-sm-6 col-md-4 col-12 text-center">
                  <img
                    className="m-1"
                    src={require("../../Assets/images/18.jpg")}
                    alt="icon"
                  />{" "}
                </div>{" "}
                <div className="col-sm-6 col-md-4 col-12 text-center">
                  <img
                    className="m-1"
                    src={require("../../Assets/images/19.jpg")}
                    alt="icon"
                  />{" "}
                </div>{" "}
                <div className="col-sm-6 col-md-4 col-12 text-center">
                  <img
                    className="m-1"
                    src={require("../../Assets/images/3232.jpg")}
                    alt="icon"
                  />{" "}
                </div>{" "}
              </div>
            </>
          ) : (
            <PaymentBtn />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            إغلاق
          </Button>
        </Modal.Footer>
      </Modal>
      <SecondHeader />
      <div className="bg-url">
        <Container className="f-eng">
          <div className=" d-flex align-items-center flex-column pb-5">
            <h3 className="text-white mt-4 fw-bold fs-1 mx-auto">Payment</h3>
            {loading ? (
              <Loading />
            ) : (
              <div className="col-md-12 d-flex align-items-center justify-content-center">
                <Form className="col-12" onSubmit={HandleSendData}>
                  {paymentMethodsShow}

                  {showPayBtn && (
                    <Button
                      type="submit"
                      className="mx-auto d-flex text-decoration-none bg-blue mt-5 w-fit-content"
                    >
                      {CircleLoadingIcon && <Loading />}

                      <h5 className=" text-white rounded mb-0 py-3 px-4 h-100">
                        ادفع
                        <span style={{ color: "#3A4E5D" }}>
                          {lastPrice}
                        </span>{" "}
                        ج.م
                      </h5>
                    </Button>
                  )}
                </Form>
              </div>
            )}
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
}
