import { useEffect, useState } from "react";
import { Axios } from "../../Api/axios";
import { ABOUT } from "../../Api/Api";
import parse from "html-react-parser";
import { Container } from "react-bootstrap";
import SecondHeader from "../../Components/Header/SecondHeader";
import Footer from "../../Components/Footer/Footer";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";
import { Helmet } from "react-helmet";

export default function About() {
  const [data, setData] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  useEffect(() => {
    Axios.get(`/${ABOUT}`)
      .then((data) => setData(data.data.data.description))
      .finally(() => setLoadingSpinner(false));
  }, []);

  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>عن الشركة - أمواج</title>
        </Helmet>
        <SecondHeader />
        {loadingSpinner && <LoadingSpinner />}
        <Container className="fs-3 mt-5">{parse(data)}</Container>
        <Footer />
      </div>
    </>
  );
}
