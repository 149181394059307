import { createContext, useState } from "react";

export const Ticket = createContext("");

export default function TicketContext({ children }) {
  const [ticket, setTicket] = useState({ price: 0 });
  return (
    <Ticket.Provider value={{ ticket, setTicket }}>{children}</Ticket.Provider>
  );
}
