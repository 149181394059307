import { Form } from "react-bootstrap";

import "./Home.css";
import { useEffect, useState } from "react";
import { Axios } from "../../Api/axios";
import { LOCATIONS, TRIP, baseURL } from "../../Api/Api";

import "react-day-picker/dist/style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

import CircleLoading from "../../Components/Loading/CircleLoading";
import { useNavigate } from "react-router-dom";
import { DayPicker } from "react-day-picker";
import Footer from "../../Components/Footer/Footer";
import SecondHeader from "../../Components/Header/SecondHeader";

export const dateForm = (date) => {
  const selectedDate = new window.Date(date);
  const fullYear = selectedDate.getFullYear();
  const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if needed
  const day = selectedDate.getDate().toString().padStart(2, "0"); // Add leading zero if needed

  return `${fullYear}-${month}-${day}`;
};

export default function HomePage() {
  // Radio
  const [radio, setRadio] = useState(1);
  const [openBack, setOpenBack] = useState(false);
  // Date
  const [date, setDate] = useState([]);

  const [openDate, setOpenDate] = useState(false);
  const [singleDate, setSingleDate] = useState(new Date());
  const [backDateMobile, setDateMobile] = useState(new Date());
  const getSingleDate =
    typeof singleDate !== "undefined"
      ? dateForm(singleDate)
      : "اختر تاريخ الذهاب";
  const getMultiDates = {
    from:
      date?.from && date !== "undefined"
        ? dateForm(date?.from)
        : "اختر تاريخ الذهاب",
    to:
      date?.to && date !== "undefined"
        ? dateForm(date?.to)
        : "اختر تاريخ العودة",
  };

  const finalDate = radio === 0 ? getMultiDates.from : dateForm(singleDate);

  // Search Form
  const [form, setForm] = useState({
    pick_location_id: "اختر مكان الذهاب",
    drop_location_id: "اختر مكان العودة",
  });
  // Locations
  const [location, setLocations] = useState([]);

  console.log(location.filter((loc) => loc.id === "1"));
  // Loading
  const [CircleLoadingIcon, setCircleLoadingIcon] = useState(false);

  const nav = useNavigate();

  useEffect(() => {
    Axios.get(`${baseURL}/${LOCATIONS}`)
      .then((data) => {
        setLocations(data.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  // HandleSearch
  async function handleSearch(e) {
    e.preventDefault();
    setCircleLoadingIcon(true);
    const formData = new FormData();
    formData.append("journeydate", finalDate);
    formData.append("pick_location_id", Number(form.pick_location_id));
    formData.append("drop_location_id", Number(form.drop_location_id));

    try {
      const res = await Axios.post(`${TRIP}`, formData, {});
      localStorage.setItem("openBack", openBack);
      if (res.data.data) {
        localStorage.setItem("trip", JSON.stringify(res.data.data));
      } else {
        localStorage.setItem("trip", JSON.stringify([{ notFound: "" }]));
      }
      localStorage.setItem(
        "localTrip",
        JSON.stringify({
          goDate: radio === 0 ? getMultiDates.from : dateForm(singleDate),
          backDate: radio === 0 ? getMultiDates.to : "no back date",
          pick_location_id: Number(form.pick_location_id),
          drop_location_id: Number(form.drop_location_id),
          drop_location: location.filter(
            (loc) => loc.id === form.drop_location_id
          )[0].name,
          pick_location: location.filter(
            (loc) => loc.id === form.pick_location_id
          )[0].name,
        })
      );
      nav("trips");
      setCircleLoadingIcon(false);
    } catch (err) {
      setCircleLoadingIcon(false);
    }
  }

  // Handle Change
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  const showLocations = location.map((location, key) => (
    <option
      key={key}
      disabled={
        form.drop_location_id === location.id ||
        form.pick_location_id === location.id
      }
      value={location.id}
    >
      {location.name}
    </option>
  ));

  return (
    <div className="position-relative">
      <SecondHeader />
      <div className="landing min-vh-100 position-relative">
        <div className="position-absolute top-50 translate-middle start-50 text-white col-md-10 col-11 text-center ">
          <h1>أبدأ لحجز رحلتك</h1>
          <div
            dir="rtl"
            className=" p-md-5 p-3  rounded shadow row-gap-3"
            style={{ backgroundColor: "rgba(5, 62, 129, 0.8)" }}
          >
            <div className="d-flex align-items-center justify-content-start flex-wrap row-gap-2 mb-4">
              <div
                onClick={() => {
                  setRadio(1);
                  setOpenBack(false);
                }}
                className="d-flex align-items-center justify-content-start col-lg-3 col-md-6 col-12  gap-2 pointer"
              >
                <div
                  className={`rounded-circle border custom-radio position-relative me-lg-0   ${
                    radio === 1 && !openBack && "custom-radio-btn"
                  }`}
                >
                  <span></span>
                </div>
                <h4 className="mb-0">ذهاب</h4>
              </div>
              <div
                onClick={() => {
                  setOpenBack(false);
                  setRadio(0);
                }}
                className="d-flex align-items-center justify-content-start col-lg-3 col-md-6 col-12  gap-2 pointer"
              >
                <div
                  className={`rounded-circle border custom-radio position-relative me-lg-0   ${
                    radio === 0 && "custom-radio-btn"
                  }`}
                >
                  <span></span>
                </div>
                <h4 className="mb-0">ذهاب وعودة</h4>
              </div>
              <div
                onClick={() => {
                  setRadio(1);
                  setOpenBack(true);
                }}
                className="d-flex align-items-center justify-content-start col-lg-3 col-md-6 col-12  gap-2 pointer"
              >
                <div
                  className={`rounded-circle border custom-radio position-relative me-lg-0   ${
                    radio === 1 && openBack && "custom-radio-btn"
                  }`}
                >
                  <span></span>
                </div>
                <h4 className="mb-0">ذهاب وعودة (مفتوحة) </h4>
              </div>
            </div>
            <Form className="d-flex align-items-stretch justify-content-between flex-wrap">
              <Form.Group
                className="d-flex align-items-center justify-content-center row-gap-3 flex-wrap col-lg-9  col-12 landing-inputs"
                controlId=""
              >
                <div className="position-relative text-end col-md-6 col-12">
                  <Form.Label className="fs-4 gold ms-4">
                    <FontAwesomeIcon
                      className="text-white ms-2 fs-6"
                      icon={faCalendarDays}
                    />
                    تاريخ الذهاب:
                  </Form.Label>

                  <div className="d-md-none mb-2">
                    <Form.Control
                      value={dateForm(singleDate)}
                      onChange={(e) => {
                        setSingleDate(new Date(e.target.value));
                        setDate((date) => {
                          return {
                            ...date,
                            from: new Date(e.target.value),
                          };
                        });
                      }}
                      min={dateForm(new Date())}
                      type="date"
                    />
                  </div>
                  {radio !== 1 && (
                    <>
                      <Form.Label className="fs-4 gold">
                        <FontAwesomeIcon
                          className="text-white ms-2 fs-6"
                          icon={faCalendarDays}
                        />
                        تاريخ العودة:
                      </Form.Label>
                      <div className="d-md-none">
                        <Form.Control
                          value={dateForm(backDateMobile)}
                          min={dateForm(new Date())}
                          onChange={(e) => {
                            setDateMobile(e.target.value);
                            setDate((date) => {
                              return {
                                ...date,
                                to: new Date(e.target.value),
                              };
                            });
                          }}
                          type="date"
                        />
                      </div>
                    </>
                  )}
                  <div className="bg-white text-black cutsom-date d-md-block d-none position-relative">
                    {radio !== 1 ? (
                      <div
                        className="d-flex align-items-center "
                        onClick={() => setOpenDate((prev) => !prev)}
                      >
                        <p className="m-0 f-eng ms-5">{getMultiDates.from}</p>
                        <p className="m-0 f-eng ">{getMultiDates.to}</p>
                      </div>
                    ) : (
                      <div
                        className="d-flex align-items-center"
                        onClick={() => setOpenDate((prev) => !prev)}
                      >
                        <p className="m-0 f-eng ms-5">{getSingleDate}</p>
                      </div>
                    )}
                    {openDate && (
                      <div
                        className="bg-white text-black cutsom-date d-md-block d-none position-absolute top-100 z-3"
                        dir="ltr"
                      >
                        {radio !== 1 ? (
                          <>
                            <DayPicker
                              selected={date}
                              onSelect={(d) => {
                                setDate(d);
                                setOpenDate(false);
                              }}
                              disabled={{ before: new Date() }}
                              mode="range"
                              id="multi"
                              numberOfMonths={2}
                            />
                          </>
                        ) : (
                          <DayPicker
                            mode="single"
                            disabled={{ before: new Date() }}
                            selected={singleDate}
                            onSelect={(d) => {
                              setSingleDate(d);
                              setOpenDate(false);
                            }}
                            id="single"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="position-relative text-end col-md-3 col-6 ">
                  <Form.Label className="fs-4 gold">
                    <FontAwesomeIcon
                      className="text-white ms-2 fs-6"
                      icon={faLocationDot}
                    />
                    من:
                  </Form.Label>
                  <Form.Select
                    onChange={handleChange}
                    name="pick_location_id"
                    dir="ltr"
                    className="landing-input-2 "
                    defaultValue="اختر مكان الذهاب"
                  >
                    <option value="اختر مكان الذهاب" disabled>
                      اختر مكان الذهاب
                    </option>
                    {showLocations}
                  </Form.Select>
                </div>
                <div className="position-relative text-end col-md-3 col-6">
                  <Form.Label className="fs-4 gold">
                    <FontAwesomeIcon
                      className="text-white ms-2 fs-6"
                      icon={faLocationDot}
                    />
                    الى:
                  </Form.Label>
                  <Form.Select
                    onChange={handleChange}
                    name="drop_location_id"
                    dir="ltr"
                    className="landing-input-3 "
                    defaultValue="اختر مكان العودة"
                  >
                    <option value="اختر مكان العودة" disabled>
                      اختر مكان العودة
                    </option>
                    {showLocations}
                  </Form.Select>
                </div>
              </Form.Group>
              <div className="col-lg-3 col-12 ">
                <h3 className="gold mt-2 mb-0">ذهاب وعودة</h3>
                <button
                  onClick={handleSearch}
                  disabled={
                    form.drop_location_id === "" ||
                    form.pick_location_id === "" ||
                    form.drop_location_id === "اختر مكان الذهاب" ||
                    form.drop_location_id === "اختر مكان العودة" ||
                    radio === 0
                      ? getMultiDates.from === "اختر تاريخ الذهاب" ||
                        getMultiDates.to === "اختر تاريخ العودة" ||
                        form.drop_location_id === "اختر مكان الذهاب" ||
                        form.drop_location_id === "اختر مكان العودة"
                      : getSingleDate === "اختر تاريخ الذهاب" ||
                        form.drop_location_id === "اختر مكان الذهاب" ||
                        form.drop_location_id === "اختر مكان العودة" ||
                        CircleLoadingIcon
                  }
                  className="bg-gold border-0 col-md-8 col-12 m-0 fs-3 shadow text-decoration-none text-white btn position-relative gap-3"
                  style={{
                    padding: "1px 0",
                    cursor: CircleLoadingIcon && "not-allowed",
                  }}
                >
                  <div className="d-flex align-items-stretch justify-content-center gap-2">
                    <p className="m-0"> بحث</p>
                    {CircleLoadingIcon && <CircleLoading />}
                  </div>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
