import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.css";

export default function Footer() {
  return (
    <>
      <footer className="shadow bg-gray  pt-5">
        <Container>
          <div dir="rtl" className="row justify-content-between">
            <div className="col-md-4 mt-md-0 mt-3">
              <h5 className="text-uppercase fs-1 primary">
                شركة أمواج للنقل الداخلي
              </h5>
              <h5 className="seconde">
                تفسر شركة امواج ترافيل كيف تعمل على جمع واستخدام وحماية البيانات
                الشخصية الخاصة بالعميل اثناء استخدام او فترة تواجدة على موقعنا
                الالكترونى .
              </h5>
            </div>

            <hr className="clearfix w-100 d-md-none pb-0" />

            <div className="col-md-3 mb-md-0 mb-3">
              <h5 className="text-uppercase primary fs-3">الروابط</h5>
              <div className="list-unstyled d-flex flex-column">
                <Link className="mb-1" to={"/"}>
                  الصفحة الرئيسية
                </Link>
                <Link className="mb-1" to={"/"}>
                  المحطات
                </Link>
                <Link to={"/contact"}>اتصل بنا</Link>
              </div>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
              <h5 className="text-uppercase primary fs-3">الروابط</h5>
              <div className="list-unstyled d-flex flex-column">
                <Link className="mb-1" to={"/about-us"}>
                  عن الشركة
                </Link>
                <Link className="mb-1" to={"/faq"}>
                  الاسئلة الشائعة
                </Link>
                <Link className="mb-1" to={"/terms"}>
                  الشروط والأحكام
                </Link>
                <Link to={"/policy"}>سياسة الخصوصية</Link>
              </div>
            </div>
          </div>
          <h3 className="text-center pt-3 pb-1  primary">جميع الحقوق محفوظة</h3>
        </Container>
      </footer>
    </>
  );
}
