export default function Btn(props) {
  return (
    <h5
      className={`  ${props.classes}  bg-gold text-center text-black rounded py-2 px-2 m-0 mb-lg-0 mb-2`}
      style={{ cursor: "pointer" }}
    >
      {props.title}
    </h5>
  );
}
