import { Link } from "react-router-dom";
import "./404.css";
import SecondHeader from "../../Components/Header/SecondHeader";
import Footer from "../../Components/Footer/Footer";

export default function Page404() {
  return (
    <>
      <SecondHeader />
      <p class="zoom-area">
        <b>Amwaj</b> Cannot Find This Page
      </p>
      <section class="error-container">
        <span class="four">
          <span class="screen-reader-text">4</span>
        </span>
        <span class="zero">
          <span class="screen-reader-text">0</span>
        </span>
        <span class="four">
          <span class="screen-reader-text">4</span>
        </span>
      </section>
      <div class="link-container mb-5 fs-4">
        <Link to="/" class="more-link">
          العودة الى الصفحة الرئيسية
        </Link>
      </div>
      <Footer />
    </>
  );
}
