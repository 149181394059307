import { Button, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";

import { LOGOUT } from "../../Api/Api";
import { Axios } from "../../Api/axios";
import React from "react";
import Cookie from "cookie-universal";

export default function SecondHeader(props) {
  const cookie = Cookie();
  const accessToken = cookie.get("amawaj-pri");
  // Handle Logout
  async function handleLogout() {
    try {
      const res = await Axios.post(`/${LOGOUT}`, null, {});
      if (res.status === 200) {
        localStorage.removeItem("tickets");
        localStorage.removeItem("localTrip");
        localStorage.removeItem("trip");
        window.location.pathname = "/login";
        cookie.remove("amawaj-pri");
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="z-2 pt-md-3 pt-1 px-3 d-flex flex-row w-100 bg-white pb-0 shadow"
      dir="rtl"
    >
      <Link to="/">
        <img
          width={"120px"}
          src={require("../../Assets/images/logo.png")}
          alt="logo"
          className={`pb-md-2 pb-1 logo `}
        />
      </Link>
      <div className="d-sm-flex d-md-none align-items-center justify-content-center flex-column">
        <h3 className="primary fs-2 mb-0">{props.title}</h3>
        <h4 className="seconde mb-0">{props.secondTitle}</h4>
      </div>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="d-felx justify-content-between"
      >
        <div
          dir="rtl"
          className="d-lg-flex align-items-center justify-content-between mt-lg-0 mt-3"
        >
          <div className="d-lg-flex align-items-start justify-content-start flex-column">
            <ul className="d-lg-flex align-items-center justify-content-start ul-nav mb-1">
              <NavLink className="ms-5 fs-5" to="/about-us">
                <li>عن الشركة</li>
              </NavLink>
              <NavLink className="ms-5 fs-5" to="/faq">
                <li>الاسئلة الشائعة</li>
              </NavLink>
              <NavLink className="ms-5 fs-5" to="/terms">
                <li>الشروط والأحكام</li>
              </NavLink>
              <NavLink className="ms-5 fs-5" to="/policy">
                <li>سياسة الخصوصية</li>
              </NavLink>
              <NavLink className="ms-5 fs-5" to="/contact">
                <li>اتصل بنا</li>
              </NavLink>
            </ul>
            <div className="d-lg-flex align-items-center justify-content-start ul-nav-2 me-4 ">
              <NavLink className="ms-5 fs-5 pb-2" to="/">
                الصفحة الرئيسية
              </NavLink>
              <NavLink className="ms-5 fs-5 pb-2" to="/d">
                المحطات
              </NavLink>
            </div>
          </div>
        </div>

        {accessToken ? (
          <div className="d-flex align-items-center justify-content-between gap-4 py-4 py-lg-0">
            <Link to={"/profile"}>
              <div className="rounded-circle d-flex align-items-center justify-content-center w-fit-content">
                <FontAwesomeIcon
                  icon={faCircleUser}
                  className="fs-1"
                  color="gray"
                />
              </div>
            </Link>
            <Button
              className="bg-seconde text-white border-0 rounded text-decoration-none fs-5 w-fit-content px-3"
              onClick={handleLogout}
            >
              تسجيل الخروج
            </Button>
          </div>
        ) : (
          <div className="d-flex align-self-center justify-content-lg-evenly gap-4">
            <Link
              to={"/login"}
              className="bg-gold text-white rounded text-decoration-none fs-5 w-fit-content px-3 py-1"
            >
              تسجيل الدخول
            </Link>
            <Link
              to={"/register"}
              className="bg-seconde text-white rounded text-decoration-none fs-5 w-fit-content px-3 py-1"
            >
              تسجيل حساب
            </Link>
          </div>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}
