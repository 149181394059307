import { Button, Container, Modal } from "react-bootstrap";
import SecondHeader from "../../Components/Header/SecondHeader";
import { useContext, useEffect, useState } from "react";

import { Axios } from "../../Api/axios";
import { PROFILE, TICKETSBOOKED, TICKETSDITAILS } from "../../Api/Api";
// import Cookie from "cookie-universal";

import { Link, useNavigate } from "react-router-dom";

import LoadingSpinner from "../../Components/Loading/LoadingSpinner";
import Footer from "../../Components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faTicket } from "@fortawesome/free-solid-svg-icons";

import { Ticket } from "../../Context/TicketContext";
import { dateForm } from "../Home/Home";

import { toast, ToastContainer } from "react-toastify";

export default function Profile() {
  const [form, setForm] = useState({
    Wallet_ballance: "",
    job: "",
    email: "",
    mobile: "",
    first_name: "",
    last_name: "",
    gender: "أختر الجنس",
    id_type: "ID Type",
    id_number: "",
  });
  const [tickets, setTickets] = useState([]);
  const [singleTicket, setSingleTicket] = useState("");
  const [loading, setLoading] = useState(true);
  const [circleLoading, setCircleLoading] = useState(false);
  const [circleLoadingInfo, setCircleLoadingInfo] = useState(false);

  // Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  // Context
  const GetTicket = useContext(Ticket);
  // Nav
  const nav = useNavigate();

  // Get Data
  useEffect(() => {
    Axios.get(`/${PROFILE}`, {}).then((res) => {
      if (res.data.data === "token not valid") {
        nav("/login");
      }
      setForm(res.data.data);
      setLoading(false);
    });
    Axios.get(`/${TICKETSBOOKED}`, {}).then((res) => {
      setTickets(res.data);
    });
  }, []);

  useEffect(() => {
    const previousPage = document.referrer;
    const search = new URLSearchParams(previousPage);
    const transaction_status = search.get("transaction_status");

    if (transaction_status === "success") {
      toast.success("تمت عملية الدفع بنجاح");
    } else if (transaction_status === "failed") {
      toast.error("فشلت عملية الدفع");
    }
  }, []);

  // Get One Ticket
  async function HandleTicket(id) {
    setCircleLoading(true);
    await Axios.get(`/${TICKETSDITAILS}/${id}?type=1`, {}).then((res) => {
      console.log(res);
      localStorage.setItem("tickets", res.data[0].booking_id);
      GetTicket.setTicket({ price: Number(res.data[0].price) });
    });
    nav("/payment");
    setCircleLoading(false);
  }

  // Get One Ticket For Info
  async function handleShow(id) {
    setCircleLoadingInfo(true);
    setShow(true);
    await Axios.get(`/${TICKETSDITAILS}/${id}`, {}).then((res) => {
      setSingleTicket(res.data);
    });
    setCircleLoadingInfo(false);
  }

  const showTickts = tickets?.map((ti) => (
    <div className=" col-lg-4 col-md-6 col-12">
      <div className="py-4 px-3 shadow position-relative rounded mx-1 d-flex align-items-center flex-column">
        <FontAwesomeIcon icon={faTicket} />
        <FontAwesomeIcon
          onClick={() => handleShow(ti.Booking_ID)}
          className="position-absolute top-0 end-0 m-3"
          style={{ cursor: "pointer" }}
          icon={faCircleInfo}
        />
        <p className="mt-3 orange">
          تاريخ الحجز:
          <span className="f-eng text-black fs-6"> {ti.Journey_date}</span>
        </p>
        <p className="orange">
          وقت الاقلاع:
          <span className="f-eng text-black fs-6"> {ti.Lunch_time}</span>
        </p>
        <div className="d-flex align-items-center gap-2">
          <h5
            className={`${
              ti.Payment_status === "مدفوعة"
                ? "bg-success text-white"
                : "bg-gold text-black"
            }  px-2 rounded mb-0`}
          >
            {ti.Payment_status}
          </h5>
          {ti.Payment_status === "في إنتظار الدفع" && (
            <Button
              onClick={() => HandleTicket(ti.Booking_ID)}
              disabled={circleLoading}
              className={`bg-warning px-2 rounded text-decoration-none text-black fs-5 mb-0 pointer border-0`}
            >
              <span>إتمام عملية الدفع</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <SecondHeader />

      <ToastContainer
        containerId="1"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Container className="mt-5">
        {/* Modal */}

        <Modal dir="rtl" show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>معلومات البطاقة</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {circleLoadingInfo ? (
              <LoadingSpinner />
            ) : (
              <div>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">تاريخ الرحلة :</p>
                  <p className="fs-5 mb-0 f-eng ">
                    {dateForm(singleTicket[0]?.journeydate)}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">وقت الانطلاق :</p>
                  <p className="fs-5 mb-0 f-eng ">
                    {singleTicket[0]?.lunch_time}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">محطة التحرك :</p>
                  <p className="fs-4 mb-0">{singleTicket[0]?.plname}</p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">كود الحجز :</p>
                  <p className="fs-4 mb-0">{singleTicket[0]?.booking_id}</p>
                </div>{" "}
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">من فرع :</p>
                  <p className="fs-4 mb-0">{singleTicket[0]?.psname}</p>
                </div>{" "}
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">محطة الوصول :</p>
                  <p className="fs-4 mb-0">{singleTicket[0]?.dlname}</p>
                </div>{" "}
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">الى فرع :</p>
                  <p className="fs-4 mb-0">{singleTicket[0]?.dsname}</p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">السعر :</p>
                  <p className="fs-4 mb-0">{singleTicket[0]?.price} ج.م</p>
                </div>{" "}
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">رقم الكرسي :</p>
                  <p className="fs-4 mb-0">{singleTicket[0]?.seatnumber}</p>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              إغلاق
            </Button>
          </Modal.Footer>
        </Modal>
        <div dir="rtl">
          <div>
            <div className="d-flex align-items-center justify-content-center">
              <h1
                className="seconde pb-2 fs-1 mb-4"
                style={{ borderBottom: "3px solid #ffb133" }}
              >
                معلوماتي الشخصية
              </h1>
            </div>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">رصيد المحفظة :</p>
                  <p className="fs-4 mb-0 ">
                    {form.Wallet_ballance.split(" ").map((i) =>
                      i === "L.E" ? " ج.م" : i
                    )}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">الأسم الأول:</p>
                  <p className="fs-4 mb-0">{form.first_name}</p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">الأسم الأخير :</p>
                  <p className="fs-4 mb-0">{form.last_name}</p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">البريد الألكتروني :</p>
                  <p className="fs-4 mb-0">{form.email}</p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">رقم الهاتف :</p>
                  <p className="fs-4 mb-0">{form.mobile}</p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">
                    {form.id_type === "1"
                      ? "الرقم الوطني :"
                      : "رقم جواز السفر :"}
                  </p>
                  <p className="fs-4 mb-0">{form.id_number}</p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">الجنس :</p>
                  <p className="fs-4 mb-0">
                    {form.gender === "Male" ? "ذكر" : "أنثى"}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">العمل :</p>
                  <p className="fs-4 mb-0">{form.job}</p>
                </div>
                <div>
                  <p className="orange fs-3 mb-0"> البطاقات المحجوزة :</p>
                  <div className="fs-4 mb-0 d-flex align-items-stretch justify-content-center flex-wrap row-gap-3 mt-3">
                    {tickets === "No ticket found"
                      ? "لايوجد  بطاقات"
                      : showTickts}
                  </div>
                </div>
                {/* <div className="d-flex align-items-center gap-3">
                  <p className="orange fs-3 mb-0">
                    معلومات البطاقات المحجوزة :
                  </p>
                  <p className="fs-4 mb-0">
                    {ticketsDiteales === "No ticket found"
                      ? "لايوجد  بطاقات"
                      : ""}
                  </p>
                </div> */}
              </>
            )}
          </div>

          <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
            <Link
              to="edit"
              className="bg-gold text-white mt-5 col-md-4 col-12 rounded py-1 border-0 fs-3 position-relative text-decoration-none text-center"
              type="button"
            >
              تعديل بياناتي
            </Link>
            <Link
              to="edit-password"
              className="bg-gold text-white mt-5 col-md-4 col-12 py-1 text-center rounded text-center text-decoration-none border-0 fs-3 position-relative"
              type="button"
            >
              تعديل كلمة المرور
            </Link>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
