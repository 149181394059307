import { Button, Container, Form } from "react-bootstrap";
import Loading from "../../Components/Loading/Loading";
import { Link, useNavigate } from "react-router-dom";
import SecondHeader from "../../Components/Header/SecondHeader";
import { Axios } from "../../Api/axios";
import React, { useContext, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeCircleCheck,
  faKey,
  faPhoneFlip,
} from "@fortawesome/free-solid-svg-icons";
import { ForgotPassword, OTP } from "../../Api/Api";
import Cookie from "cookie-universal";

export default function ForgetPassword() {
  // Loading Submit
  const [loading, setLoading] = useState(false);
  const [Pass, setPassword] = useState({
    mobile: "",
    otp: "",
    password: "",
    repassword: "",
  });
  // Cookie
  const cookie = Cookie();
  // Navigate
  const nav = useNavigate();

  // handle change Password
  function handleChangePassword(e) {
    setPassword({
      ...Pass,
      [e.target.name]:
        e.target.name === "otp" ? Number(e.target.value) : e.target.value,
    });
  }

  // Handle Change Password
  async function handlePassword(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await Axios.post(`${ForgotPassword}`, Pass);
      setLoading(false);
      if (res.data.response === 200) {
        // setIsAuthenticated(true);
        nav("/");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  // Send OTP
  const [disabled, setDisabled] = useState(false);
  async function repeatOtp() {
    setDisabled(true);
    const res = await Axios.post(`/${OTP}`, {
      mobile: Number(Pass.mobile),
    });
    console.log(res);
    setTimeout(() => {
      setDisabled(false);
    }, 60000);
  }
  return (
    <>
      <SecondHeader />
      <Container className="my-md-5 my-4">
        <div className="mt-md-5 mt-4">
          <Form dir="rtl" onSubmit={handlePassword}>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="col-12 d-flex flex-wrap align-items-center justify-content-between">
                <div className="d-flex flex-wrap align-items-center gap-md-4 col-lg-9 col-12">
                  <Form.Group
                    className="mb-3 col-md-6 col-12"
                    controlId="formBasicFirstName"
                  >
                    <Form.Label className="fs-2 gold"> رقم الهاتف :</Form.Label>
                    <div className="position-relative">
                      <FontAwesomeIcon
                        icon={faPhoneFlip}
                        fontSize={23}
                        className={`position-absolute end-0 translate-middle top-50`}
                      />
                      <Form.Control
                        className={`pe-5 py-3 fs-4 `}
                        type="number"
                        placeholder="ادخل رقم الهاتف"
                        onChange={handleChangePassword}
                        name="mobile"
                        value={Pass.mobile}
                      />
                    </div>
                  </Form.Group>
                  <div className="d-flex align-self-md-end mb-3 col-md-4 col-12 bg-gold rounded ">
                    <Button
                      className="col-12 fs-2 mb-0 text-white text-center bg-gold border-0"
                      type="button"
                      onClick={repeatOtp}
                      disabled={disabled}
                    >
                      ارسل رمز التحقق
                    </Button>
                  </div>
                </div>
                <Form.Group
                  className="mb-3 col-lg-2 col-12"
                  controlId="formBasicIdNumber"
                >
                  <Form.Label className="fs-2 gold"> رمز التحقق :</Form.Label>
                  <div className="position-relative">
                    <FontAwesomeIcon
                      icon={faEnvelopeCircleCheck}
                      fontSize={20}
                      className={`position-absolute end-0 translate-middle top-50`}
                    />
                    <Form.Control
                      className={`pe-5 py-3 fs-4 `}
                      type="number"
                      placeholder="رمز التحقق"
                      onChange={handleChangePassword}
                      name="otp"
                      value={Pass.otp}
                    />
                  </div>
                </Form.Group>
              </div>
              <Form.Group className="mb-3 col-12" controlId="formBasicLastName">
                <Form.Label className="fs-2 gold">
                  كلمة المرور الجديدة :
                </Form.Label>
                <div className="position-relative">
                  <FontAwesomeIcon
                    icon={faKey}
                    fontSize={22}
                    className={`position-absolute end-0 translate-middle top-50`}
                  />
                  <Form.Control
                    className={`pe-5 py-3 fs-4 `}
                    type="text"
                    placeholder="ادخل كلمة المرور الجديدة "
                    onChange={handleChangePassword}
                    name="password"
                    value={Pass.password}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3 col-12" controlId="formBasicLastName">
                <Form.Label className="fs-2 gold">
                  اعد كتابة كلمة المرور الجديدة :
                </Form.Label>
                <div className="position-relative">
                  <FontAwesomeIcon
                    icon={faKey}
                    fontSize={22}
                    className={`position-absolute end-0 translate-middle top-50`}
                  />
                  <Form.Control
                    className={`pe-5 py-3 fs-4 `}
                    type="text"
                    placeholder=" اعد ادخال كلمة المرور الجديدة "
                    onChange={handleChangePassword}
                    name="repassword"
                    value={Pass.repassword}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="d-flex flex-wrap mt-md-5 mt-3 row-gap-4 align-items-center justify-content-center">
              <Button
                className="bg-gold text-white col-md-4 col-12 py-1 border-0 fs-3 position-relative"
                type="submit"
                disabled={loading}
                style={{ height: loading && "70px" }}
              >
                {!loading && "حفظ"}
                {loading && (
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <Loading />
                  </div>
                )}
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
}
