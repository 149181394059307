import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Axios } from "../../Api/axios";
import { ChangePassword } from "../../Api/Api";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import SecondHeader from "../../Components/Header/SecondHeader";
import Footer from "../../Components/Footer/Footer";

export default function EditPassword() {
  // Loading Submit
  const [loading, setLoading] = useState(false);
  const [Pass, setPassword] = useState({
    password: "",
    repassword: "",
    oldpassword: "",
  });

  const nav = useNavigate();

  // handle change Password
  function handleChangePassword(e) {
    setPassword({ ...Pass, [e.target.name]: e.target.value });
  }

  // Handle Change Password
  async function handlePassword(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await Axios.post(`${ChangePassword}`, Pass, {});

      setLoading(false);
      nav("/profile");
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }
  return (
    <>
      <SecondHeader />
      <Container className="my-5">
        <div className="mt-5">
          <Form dir="rtl" onSubmit={handlePassword}>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <Form.Group
                className="mb-3 col-12"
                controlId="formBasicFirstName"
              >
                <Form.Label className="fs-2 gold">كلمة السر القديمة</Form.Label>
                <div className="position-relative">
                  <img
                    src={require("../../Assets/images/icon/user.png")}
                    alt={"icon"}
                    width={"25px"}
                    className={`position-absolute end-0 translate-middle top-50`}
                  />
                  <Form.Control
                    className={`pe-5 py-3 fs-4 `}
                    type="password"
                    placeholder="ادخل كلمة السر القديمة"
                    onChange={handleChangePassword}
                    name="oldpassword"
                    value={Pass.oldpassword}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3 col-12" controlId="formBasicLastName">
                <Form.Label className="fs-2 gold">
                  كلمة المرور الجديدة{" "}
                </Form.Label>
                <div className="position-relative">
                  <img
                    src={require("../../Assets/images/icon/user.png")}
                    alt={"icon"}
                    width={"25px"}
                    className={`position-absolute end-0 translate-middle top-50`}
                  />
                  <Form.Control
                    className={`pe-5 py-3 fs-4 `}
                    type="password"
                    placeholder="ادخل كلمة المرور الجديدة "
                    onChange={handleChangePassword}
                    name="password"
                    value={Pass.password}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3 col-12" controlId="formBasicLastName">
                <Form.Label className="fs-2 gold">
                  {" "}
                  اعد كتابة كلمة المرور الجديدة
                </Form.Label>
                <div className="position-relative">
                  <img
                    src={require("../../Assets/images/icon/user.png")}
                    alt={"icon"}
                    width={"25px"}
                    className={`position-absolute end-0 translate-middle top-50`}
                  />
                  <Form.Control
                    className={`pe-5 py-3 fs-4 `}
                    type="password"
                    placeholder=" اعد ادخال كلمة المرور الجديدة "
                    onChange={handleChangePassword}
                    name="repassword"
                    value={Pass.repassword}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <Button
                className="bg-gold text-white mt-5 col-md-4 col-12 py-1 border-0 fs-3 position-relative"
                type="submit"
                disabled={loading}
                style={{ height: loading && "70px" }}
              >
                {!loading && "حفظ"}
                {loading && (
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <Loading />
                  </div>
                )}
              </Button>
              <Link
                to="/profile"
                className="bg-gold text-white text-center rounded text-center text-decoration-none mt-5 col-md-4 col-12 py-1 border-0 fs-3 position-relative"
                type="button"
              >
                رجوع
              </Link>
            </div>
          </Form>
        </div>
      </Container>
      <Footer />
    </>
  );
}
