import { useEffect , useContext } from "react";
//import { Axios } from "../../Api/axios";
//import { Pay } from "../../Api/Api";

import { useNavigate } from "react-router-dom";
import Check from "../../Components/Check/Check";
import { toast } from "react-toastify";
import { trackEvent } from '../../utils/facebookPixel';
import { Ticket } from "../../Context/TicketContext";

export default function PaymentRedirect() {
  const search = new URLSearchParams(window.location.search);
  const GetTicket = useContext(Ticket);
  const amount = GetTicket.ticket.price;
  const handlePurchase = () => {
    trackEvent('Purchase', { value: amount, currency: 'EGP' });
};

  const nav = useNavigate();

  useEffect(() => {
    //const transaction_id = search.get("id");
    const transaction_status = search.get("success");
    //const tickets = localStorage.getItem("tickets");
    transaction_status === "true" ? handlePurchase() : "" ;
    transaction_status === "true" ? toast.success("تمت عملية الدفع بنجاح") : toast.error("فشلت عملية الدفع");

    nav("/profile");

    // async function send() {
    //   const res = await Axios.post(`/${Pay}`, {
    //     payment_method: 5,
    //     tickets: tickets,
    //     transaction_id: transaction_id,
    //   }).finally(() => {
    //     transaction_status === "success" ? handlePurchase() : "" ;
    //     toast.success(
    //       transaction_status === "success"
    //         ? "تمت عملية الدفع بنجاح"
    //         : "فشلت عملية الدفع"
    //     );
    //     nav("/profile");
    //   });
    // }

    // send();
  }, []);
  return <Check />;
}
