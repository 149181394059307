import { useContext, useEffect, useState } from "react";

import Btn from "../../Components/Btn/Btn";
import { Axios } from "../../Api/axios";
import { CheckSeats, baseURL } from "../../Api/Api";

import LoadingSpinner from "../../Components/Loading/LoadingSpinner";
import { ToastContainer } from "react-toastify";
import { User } from "../../Context/AuthProvider";

export default function BackTrip(props) {
  // Context
  const user = useContext(User);
  const accessToken = user.auth.accessToken;

  const [seats, setSeats] = useState([]);
  const [localTrip, setLocalTrips] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const localTrip = JSON.parse(localStorage.getItem("localTrip"));
    setLocalTrips(localTrip);
    async function getBackDate() {
      // settripsBack(res.data.data);
      // const trip = JSON.parse(localStorage.getItem("trip"));
      const pick_stand = localTrip.drop_location_id;
      const journeydate = localTrip.backDate;
      const tripId = localStorage.getItem("backId");
      props.setTripId(tripId);
      Axios.get(
        `${baseURL}/${CheckSeats}?pick_stand=${pick_stand}&journeydate=${journeydate}&trip_id=${tripId}`
      )
        .then((response) => {
          const { booked_seats } = response.data;
          const seats = booked_seats.split(",").map(Number);
          setSeats(seats);
        })
        .finally(() => setLoading(false));
    }
    localTrip?.backDate !== "no back date" && getBackDate();
  }, []);

  return (
    <>
      <ToastContainer
        containerId="2"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div
        className={`col-12 d-flex align-items-center  justify-content-${
          loading ? "center" : "end"
        } flex-lg-row flex-column-reverse shadow rounded bg-white-opacity position-relative`}
        style={{ minHeight: "390px" }}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="col-lg-4 col-12 p-3">
              <div
                dir="rtl"
                className="col-12 d-flex align-items-center justify-content-between"
              >
                <h5 className="m-0">سفر من {localTrip?.drop_location}</h5>
                <img
                  src={require("../../Assets/images/icon/front-of-bus.png")}
                  alt=""
                  width="35px"
                />
                <h5 className="m-0"> الى {localTrip?.pick_location}</h5>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-5 gap-5">
                <div className="text-center">
                  <img
                    width="30px"
                    src={require("../../Assets/images/icon/red.png")}
                    alt=""
                  />
                  <p>مقعد محجوز</p>
                </div>
                <div className="text-center">
                  <img
                    width="30px"
                    src={require("../../Assets/images/icon/empty.png")}
                    alt=""
                  />
                  <p>مقعد فارغ</p>
                </div>
                <div className="text-center">
                  <img
                    width="30px"
                    src={require("../../Assets/images/icon/green.png")}
                    alt=""
                  />
                  <p>مقعد قمت بحجزه</p>
                </div>
              </div>
              <h5 dir="rtl">
                المقاعد المحجوزة :{" "}
                {props.selectedSeats.map((item) => item + ",")}
              </h5>
            </div>
            <div className="d-flex flex-column row-gap-2 p-lg-5 p-2 chairs-pos">
              <div className="d-flex justify-content-between align-items-center w-300">
                <Btn classes="bg-danger text-white m-0 px-5 " title="السائق" />
                <p className="m-0 rounded px-3 me-3 fs-5 py-1 f-eng  bg-secondary text-white w-fit-content">
                  0
                </p>
              </div>
              <div
                className="d-flex flex-wrap w-300 position-relative chairs-height"
                dir="rtl"
              >
                <img
                  src={require("../../Assets/images/icon/toilet.png")}
                  alt=""
                  className="position-absolute chair"
                  style={{ right: "0", top: `${5 * 40}px` }}
                />{" "}
                <img
                  src={require("../../Assets/images/icon/toilet.png")}
                  alt=""
                  className="position-absolute chair"
                  style={{ right: "45px", top: `${5 * 40}px` }}
                />
                <img
                  src={require("../../Assets/images/icon/toilet.png")}
                  alt=""
                  className="position-absolute chair"
                  style={{ right: "0", top: `${6 * 40}px` }}
                />{" "}
                <img
                  src={require("../../Assets/images/icon/toilet.png")}
                  alt=""
                  className="position-absolute chair"
                  style={{ right: "45px", top: `${6 * 40}px` }}
                />
                {Array.from({ length: 49 }, (_, seatIndex) => {
                  const rowIndex = Math.floor(seatIndex / 4);
                  const colIndex = seatIndex % 4;
                  const isBooked = seats.includes(seatIndex + 1);
                  const isSelected = props.selectedSeats.includes(
                    seatIndex + 1
                  );
                  const imageIndex = rowIndex * 4 + colIndex;
                  let leftStyle;
                  if (seatIndex === 48) {
                    leftStyle = "0";
                  } else if (seatIndex === 46) {
                    leftStyle = "50%";
                  } else if (seatIndex === 47) {
                    leftStyle = "45px";
                  } else if (seatIndex === 20) {
                    leftStyle = "45px";
                  } else if (seatIndex === 21) {
                    leftStyle = "0px";
                  } else if (colIndex === 2) {
                    leftStyle = "45px";
                  } else if (colIndex === 3) {
                    leftStyle = "0px";
                  } else {
                    leftStyle = colIndex * 45 + "px";
                  }

                  let rightStyle;
                  if (colIndex === 2 || colIndex === 3) {
                    rightStyle = "";
                  } else if (seatIndex === 48) {
                    rightStyle = "";
                  } else if (seatIndex === 20 || seatIndex === 21) {
                    rightStyle = "";
                  } else {
                    rightStyle = colIndex * 45 + "px";
                  }

                  let topStyle;
                  if (seatIndex > 21 && seatIndex < 48) {
                    topStyle = `${rowIndex * 40 + 40}px`;
                  } else if (seatIndex === 48) {
                    topStyle = `${rowIndex * 40}px`;
                  } else {
                    topStyle = `${rowIndex * 40}px`;
                  }
                  return (
                    <div key={seatIndex}>
                      {isBooked === true ? (
                        <img
                          className="position-absolute chair"
                          style={{
                            top: topStyle,
                            left: leftStyle,
                            right: rightStyle,
                          }}
                          src={require("../../Assets/images/icon/red.png")}
                          alt=""
                        />
                      ) : isSelected ? (
                        <img
                          onClick={() => {
                            props.setSelectedSeats((prev) =>
                              prev.filter((seat) => seat !== seatIndex + 1)
                            );
                          }}
                          className="position-absolute chair"
                          style={{
                            cursor: "pointer",
                            top: topStyle,
                            left: leftStyle,
                            right: rightStyle,
                          }}
                          src={require("../../Assets/images/icon/green.png")}
                          alt=""
                        />
                      ) : (
                        <div
                          className="position-absolute w-custom"
                          onClick={() => {
                            if (
                              props.maxBackCount > props.selectedSeats.length
                            ) {
                              props.setSelectedSeats((prev) => [
                                ...prev,
                                seatIndex + 1,
                              ]);
                            }
                          }}
                          style={{
                            cursor: "pointer",
                            top: topStyle,
                            left: leftStyle,
                            right: rightStyle,
                          }}
                        >
                          <div className="position-relative">
                            <img
                              src={require("../../Assets/images/icon/empty.png")}
                              className="chair-img"
                              alt=""
                            />
                            <span
                              className="position-absolute chair-nums"
                              style={{
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              {seatIndex + 1}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
