import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Axios } from "../../Api/axios";
import Loading from "../../Components/Loading/Loading";
import SecondHeader from "../../Components/Header/SecondHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faCommentDots,
  faEnvelope,
  faMessage,
  faPhoneFlip,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Components/Footer/Footer";
import { ContactUs } from "../../Api/Api";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";

export default function Contact() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    mobile: "",
    message: "",
    type: "باص",
  });

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleChange(e) {
    if (e.target.name === "mobile") {
      const sanitizedValue = e.target.value.replace(/\D/g, ""); // Remove non-digits
      setForm({ ...form, [e.target.name]: sanitizedValue });
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  }
  // Validate

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      if (form.mobile.length !== 11) {
        setLoading(false);
        setValidated(true);
      } else {
        const res = await Axios.post(`/${ContactUs}`, form);
        console.log(res);
        if (res?.data?.status === "fail") {
          setValidated(true);
          setLoading(false);
        } else {
          setValidated(false);
          setLoading(false);
          toast.success("تم ارسال الرسالة بنجاح");
          form.name = "";
          form.email = "";
          form.mobile = "";
          form.subject = "";
          form.message = "";
          form.type = "باص";
        }
      }
    } catch (err) {
      console.log(err);
      toast.success("لم يتم ارسال الرسالة , يرجى اعادة المحاولة لاحقا");
    }
  }
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>تواصل معنا - أمواج</title>
      </Helmet>
      <SecondHeader />

      <Container className="mb-4">
        <div className="d-flex justify-content-center flex-column align-items-center mt-5">
          <h1 className="primary">
            لا تتردد في الاتصال بنا للحصول على أي نوع من المعلومات
          </h1>
          <Form
            noValidate
            validated={validated}
            className="col-md-10 col-12 d-flex align-items-center flex-wrap mt-4"
            onSubmit={handleSubmit}
          >
            <div className="col-md-6 col-12">
              <Form.Group
                className="mb-3 ms-md-5"
                controlId="formBasicUserName"
              >
                <Form.Label className="fs-2 gold">اسم المستخدم</Form.Label>
                <div className="position-relative">
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    className={`position-absolute end-0 ${
                      validated ? "top-35" : "top-50"
                    } translate-middle`}
                    fontSize={25}
                  />
                  <Form.Control
                    className="pe-5 py-3 fs-4"
                    type="text"
                    placeholder="اكتب اسمك "
                    required
                    onChange={handleChange}
                    name="name"
                    value={form.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    الرجاء إدخال الأسم
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>محقق</Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            <div className="col-md-6 col-12">
              <Form.Group className="mb-3" controlId="formBasicemail">
                <Form.Label className="fs-2 gold">البريد الألكتروني</Form.Label>
                <div className="position-relative">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={`position-absolute end-0 ${
                      validated ? "top-35" : "top-50"
                    } translate-middle`}
                    fontSize={25}
                  />
                  <Form.Control
                    className="pe-5 py-3 fs-4"
                    type="email"
                    placeholder="البريد الألكتروني"
                    required
                    onChange={handleChange}
                    name="email"
                    value={form.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    الرجاء أدخل البريد الألكتروني
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>محقق</Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            <div className="col-md-6 col-12">
              <Form.Group className="mb-3 ms-md-5" controlId="formBasicSubject">
                <Form.Label className="fs-2 gold">الموضوع</Form.Label>
                <div className="position-relative">
                  <FontAwesomeIcon
                    icon={faCommentDots}
                    className={`position-absolute end-0 ${
                      validated ? "top-35" : "top-50"
                    } translate-middle`}
                    fontSize={25}
                  />
                  <Form.Control
                    className="pe-5 py-3 fs-4"
                    type="text"
                    placeholder="اكتب موضوع الرسالة"
                    required
                    onChange={handleChange}
                    name="subject"
                    value={form.subject}
                  />
                  <Form.Control.Feedback type="invalid">
                    اكتب موضوع الرسالة
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>محقق</Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            <div className="col-md-6 col-12">
              <Form.Group className="mb-3 " controlId="formBasicUserName">
                <Form.Label className="fs-2 gold">رقم الهاتف</Form.Label>
                <div className="position-relative">
                  <FontAwesomeIcon
                    icon={faPhoneFlip}
                    className={`position-absolute end-0 ${
                      validated ? "top-35" : "top-50"
                    } translate-middle`}
                    fontSize={25}
                  />
                  <Form.Control
                    className="pe-5 py-3 fs-4"
                    type="text"
                    placeholder="اكتب رقم الهاتف"
                    required
                    onChange={handleChange}
                    name="mobile"
                    value={form.mobile}
                    pattern="[0-9]{11}"
                  />
                  <Form.Control.Feedback type="invalid">
                    رقم الهاتف يجب ان يكون مكون من 11 رقم
                  </Form.Control.Feedback>

                  <Form.Control.Feedback>محقق</Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            <div className="col-md-6 col-12">
              <Form.Group className="mb-3 " controlId="formBasicUserName">
                <Form.Label className="fs-2 gold">النوع</Form.Label>
                <div className="position-relative">
                  <Form.Select
                    className="pe-5 py-3 fs-4"
                    type="number"
                    placeholder="اكتب رقم الهاتف"
                    required
                    onChange={handleChange}
                    name="type"
                    value={form.type}
                  >
                    <option value="باص">باص</option>
                    <option value="تأشيرات">تأشيرات</option>
                    <option value="حجز طيران">حجز طيران</option>
                    <option value="حج وعمرة">حج وعمرة</option>
                    <option value="فنادق">فنادق</option>
                    <option value="شكوى">شكوى</option>
                    <option value="إقتراح">إقتراح</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    الرجاء اختيار النوع
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>محقق</Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group className="mb-3" controlId="formBasicUserName">
                <Form.Label className="fs-2 gold">الرسالة</Form.Label>
                <div className="position-relative">
                  <FontAwesomeIcon
                    icon={faMessage}
                    className={`position-absolute end-0 translate-middle`}
                    fontSize={25}
                    style={{ top: "35px" }}
                  />
                  <Form.Control
                    as="textarea"
                    className="pe-5 py-3 fs-4"
                    placeholder="اكتب الرسالة الخاص بك"
                    style={{ height: "100px" }}
                    onChange={handleChange}
                    name="message"
                    value={form.message}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    الرجاء أدخل الرسالة الخاص بك
                  </Form.Control.Feedback>
                  <Form.Control.Feedback>محقق</Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            <div className="d-flex align-items-center justify-content-center col-12">
              <Button
                className="bg-gold text-white mt-5 col-8 py-1 border-0 fs-1 position-relative"
                type="submit"
                disabled={loading}
                style={{ height: loading && "70px" }}
              >
                {!loading && " ارسل الرسالة"}
                {loading && (
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <Loading />
                  </div>
                )}
              </Button>
            </div>
          </Form>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
