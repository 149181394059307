import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookie from "cookie-universal";

const RequireAuth = () => {
  const cookie = Cookie();
  const accessToken = cookie.get("amawaj-pri");

  return accessToken ? <Outlet /> : <Navigate to="/login" />;
};

export default RequireAuth;
