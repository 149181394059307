import { faIdCard, faVenusMars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Axios } from "../../Api/axios";
import { PROFILE, PROFILEINFO } from "../../Api/Api";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import SecondHeader from "../../Components/Header/SecondHeader";
import Footer from "../../Components/Footer/Footer";

export default function EditProfile() {
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    gender: "أختر الجنس",
    id_type: "ID Type",
    id_number: "",
  });
  // Loading Submit
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  console.log(form);

  useEffect(() => {
    Axios.get(`/${PROFILE}`, {}).then((data) =>
      setForm({
        ...form,
        first_name: data.data.data.first_name,
        last_name: data.data.data.last_name,
        gender: data.data.data.gender,
        id_type: data.data.data.id_type,
        id_number: data.data.data.id_number,
      })
    );
  }, []);

  // handle change Form
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  // Handle Change Info
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await Axios.post(`${PROFILEINFO}`, form, {});
      console.log(res);
      setLoading(false);
      nav("/profile");
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }
  return (
    <>
      <SecondHeader />
      <Container className="my-5">
        <Form dir="rtl" onSubmit={handleSubmit}>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <Form.Group
              className="mb-3 col-md-5 col-12"
              controlId="formBasicFirstName"
            >
              <Form.Label className="fs-2 gold">الأسم الأول</Form.Label>
              <div className="position-relative">
                <img
                  src={require("../../Assets/images/icon/user.png")}
                  alt={"icon"}
                  width={"25px"}
                  className={`position-absolute end-0 translate-middle top-50`}
                />
                <Form.Control
                  className={`pe-5 py-3 fs-4 `}
                  type="text"
                  placeholder="الأسم الأول"
                  onChange={handleChange}
                  name="first_name"
                  value={form.first_name}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3 col-md-5 col-12"
              controlId="formBasicLastName"
            >
              <Form.Label className="fs-2 gold">الأسم الأخير</Form.Label>
              <div className="position-relative">
                <img
                  src={require("../../Assets/images/icon/user.png")}
                  alt={"icon"}
                  width={"25px"}
                  className={`position-absolute end-0 translate-middle top-50`}
                />
                <Form.Control
                  className={`pe-5 py-3 fs-4 `}
                  type="text"
                  placeholder="الأسم الأخير"
                  onChange={handleChange}
                  name="last_name"
                  value={form.last_name}
                />
              </div>
            </Form.Group>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <Form.Group
              className="mb-3 col-md-8 col-12"
              controlId="formBasicType"
            >
              <Form.Label className="fs-2 gold">الجنس</Form.Label>
              <div className="position-relative">
                <FontAwesomeIcon
                  icon={faVenusMars}
                  className={`position-absolute end-0 translate-middle top-50`}
                  fontSize={22}
                />
                <Form.Select
                  aria-label="Default select example"
                  className={`pe-5 py-3 fs-4`}
                  onChange={handleChange}
                  name="gender"
                  value={form.gender}
                >
                  <option disabled>أختر الجنس</option>
                  <option value="1">ذكر</option>
                  <option value="2">انثى</option>
                </Form.Select>
              </div>
            </Form.Group>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
            <Form.Group
              className="mb-3 col-md-5 col-12"
              controlId="formBasicTypeNumber"
            >
              <Form.Label className="fs-2 gold">نوع ال ID</Form.Label>
              <div className="position-relative">
                <FontAwesomeIcon
                  icon={faVenusMars}
                  className={`position-absolute end-0 translate-middle top-50`}
                  fontSize={22}
                />
                <Form.Select
                  aria-label="Default select example"
                  className={`pe-5 py-3 fs-4`}
                  onChange={handleChange}
                  name="id_type"
                  value={form.id_type}
                >
                  <option disabled>ID Type</option>
                  <option value="1">National </option>
                  <option value="2">PASSPORT</option>
                </Form.Select>
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3 col-md-5 col-12"
              controlId="formBasicIdNumber"
            >
              <Form.Label className="fs-2 gold">id_number </Form.Label>
              <div className="position-relative">
                <FontAwesomeIcon
                  icon={faIdCard}
                  className={`position-absolute end-0 translate-middle top-50`}
                  fontSize={25}
                />
                <Form.Control
                  className={`pe-5 py-3 fs-4 `}
                  type="number"
                  placeholder="اكتب الرقم"
                  onChange={handleChange}
                  name="id_number"
                  value={form.id_number}
                />
              </div>
            </Form.Group>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <Button
              className="bg-gold text-white mt-5 col-md-4 col-12 py-1 border-0 fs-3 position-relative"
              type="submit"
              disabled={loading}
              style={{ height: loading && "70px" }}
            >
              {!loading && "حفظ"}
              {loading && (
                <div className="position-absolute top-50 start-50 translate-middle">
                  <Loading />
                </div>
              )}
            </Button>
            <Link
              to="/profile"
              className="bg-gold text-center rounded text-center text-decoration-none text-white mt-5 col-md-4 col-12 py-1 border-0 fs-3 position-relative"
              type="button"
            >
              رجوع
            </Link>
          </div>
        </Form>
      </Container>
      <Footer />
    </>
  );
}
