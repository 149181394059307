import { Button, Container, Form } from "react-bootstrap";
import SecondHeader from "../../Components/Header/SecondHeader";
import React, { useState } from "react";
import { Axios } from "../../Api/axios";
import { LOGIN } from "../../Api/Api";
import Loading from "../../Components/Loading/Loading";
import { Link, useNavigate } from "react-router-dom";

import Cookie from "cookie-universal";

export default function Login() {
  const [form, setForm] = useState({
    user_id: "",
    password: "",
  });
  const [err, setErr] = useState(false);
  const [sent, setSent] = useState(false);

  const cookie = Cookie();

  // Navigate
  const nav = useNavigate();
  // Loading
  const [loading, setLoading] = useState(false);

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("user_id", form.user_id);
    formData.append("password", form.password);
    try {
      const res = await Axios.post(`/${LOGIN}`, formData);
      setLoading(false);
      setSent(true);

      if (res.data.status === "fail") {
        setErr(true);
      }
      if (res.data.response === 200) {
        const accessToken = res.data.access_token;
        const refreshToken = res.data.refresh_token;
        cookie.set("amawaj-pri", accessToken);
        cookie.set("amawaj-pri-rt", refreshToken);
        window.location.pathname = "/";
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }
  return (
    <>
      <SecondHeader
        title="تسجيل الدخول"
        secondTitle="مرحبا بك في حسابك"
        none="d-none"
      />
      <div className="bg-url">
        <Container>
          <div
            dir="rtl"
            className="vh-100 d-flex justify-content-center align-items-center"
          >
            <Form className="col-md-8 col-12" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicUserName">
                <Form.Label className="fs-2 gold">
                  البريد الألكتروني او رقم الهاتف
                </Form.Label>
                <div className="position-relative">
                  <img
                    src={require("../../Assets/images/icon/email.png")}
                    alt={"icon"}
                    width={"25px"}
                    className={`position-absolute end-0 translate-middle top-50`}
                  />
                  <Form.Control
                    className={`pe-5 py-3 fs-4`}
                    type="user_id"
                    placeholder="اكتب البريد الألكتروني الخاص بك"
                    onChange={handleChange}
                    name="user_id"
                    value={form.user_id}
                  />
                  {sent && form.user_id === "" && (
                    <p style={{ color: "red" }}>
                      الرجاء أدخل البريد الألكتروني الخاص بك
                    </p>
                  )}
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className="fs-2 gold">كلمة المرور</Form.Label>
                <div className="position-relative">
                  <img
                    src={require("../../Assets/images/icon/padlock.png")}
                    alt={"icon"}
                    width={"25px"}
                    className={`position-absolute end-0 translate-middle top-50`}
                  />
                  <Form.Control
                    className={`pe-5 py-3 fs-4`}
                    type="password"
                    placeholder="كلمة المرور"
                    onChange={handleChange}
                    name="password"
                    value={form.password}
                  />
                  {sent && form.password === "" && (
                    <p className="text-danger mb-0">الرجاء أدخل كلمة المرور</p>
                  )}
                </div>
              </Form.Group>
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    className="fs-1 gold "
                    label="تذكرني"
                  />
                </Form.Group>
                <Link
                  to={"/forget-password"}
                  className="fs-1 gold pointer text-decoration-none"
                >
                  هل نسيت كلمة المرور؟
                </Link>
              </div>
              {sent && err && (
                <div className="mt-4">
                  <p className="text-danger">
                    البريد الألكتروني او كلمة المرور غير صحيحة
                  </p>
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  className="bg-gold text-white mt-5 col-8 py-1 border-0 fs-1 position-relative"
                  type="submit"
                  disabled={loading}
                  style={{ height: loading && "70px" }}
                >
                  {!loading && "تسجيل حساب"}
                  {loading && (
                    <div className="position-absolute top-50 start-50 translate-middle">
                      <Loading />
                    </div>
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </>
  );
}
