import { Link } from "react-router-dom";
import "./Trips.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Btn from "../../Components/Btn/Btn";
import { Axios } from "../../Api/axios";
import { TRIP } from "../../Api/Api";
import Footer from "../../Components/Footer/Footer";

export default function Trips() {
  // States
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [trips, setTrips] = useState([]);
  const [tripsBack, settripsBack] = useState([]);
  const [localTrip, setLocalTrip] = useState([]);
  const [chooseTrip, setChooseTrip] = useState(true);
  const [chooseFinalTrip, setChooseFinalTrip] = useState(true);

  // Get Data

  useEffect(() => {
    setTrips(JSON.parse(localStorage.getItem("trip")));
    setLocalTrip(JSON.parse(localStorage.getItem("localTrip")));
  }, []);

  async function GetBackTrips() {
    const formData = new FormData();
    formData.append("journeydate", localTrip.backDate);
    formData.append("pick_location_id", localTrip.drop_location_id);
    formData.append("drop_location_id", localTrip.pick_location_id);
    const res = await Axios.post(`${TRIP}`, formData, {});
    settripsBack(res.data.data);
  }

  const tripsShow = trips.map((trip, key) =>
    trip.notFound === "" ? (
      <h1 className="text-white mt-3 me-4" key={key}>
        لايوجد رحلات
      </h1>
    ) : (
      <div
        key={key}
        className="d-flex align-items-center justify-content-between flex-wrap px-md-3 px-2 border-bttom-opacity row-gap-3"
      >
        <div className="text-white my-md-3 my-2 px-4 m-0 text-center col-lg-3 col-md-3 col-6">
          <h5> سفر من {localTrip?.pick_location}</h5>
          <h6 className="text-uppercase f-eng" dir="ltr">
            {trip?.start_time.replace(":00am", "  AM")}
          </h6>
        </div>
        <h5 className="bg-gold text-center rounded-pill py-2 px-3 m-0 f-eng col-lg-2 col-md-4 col-6">
          {trip?.Service_type === "Standard" ? "Standard" : trip.Service_type}
        </h5>
        <div className="text-center m-0 text-white col-lg-4 col-md-4 col-12">
          <h5 className="m-0"> الوصول الى</h5>
          <h5 className="m-0  text-uppercase">{localTrip?.drop_location}</h5>
        </div>
        <div
          onClick={() => {
            trip.id === chooseTrip
              ? setChooseTrip(true)
              : setChooseTrip(() => trip.id);
            setChooseFinalTrip(true);
            localTrip.backDate !== "no back date" && GetBackTrips();
            localTrip.backDate !== "no back date" &&
              chooseTrip === true &&
              setTimeout(() => {
                window.scrollTo(0, 500);
              }, 500);
          }}
          className="col-lg-2 col-12 text-decoration-none"
        >
          <h5
            className={`bg-gold text-center text-black rounded py-2 px-2 m-0 mb-lg-0 mb-2`}
            style={{ cursor: "pointer" }}
            onClick={() => localStorage.setItem("goId", trip.id)}
          >
            {chooseTrip !== trip.id ? "اختر" : "إلغاء"}
          </h5>
        </div>
      </div>
    )
  );

  const tripsBackShow =
    localTrip.backDate !== "no back date" &&
    tripsBack.map((trip, key) =>
      trip.notFound === "" ? (
        <h1 className="text-white mt-3 me-4" key={key}>
          لايوجد رحلات
        </h1>
      ) : (
        <div
          key={key}
          id="back-trips"
          className="d-flex align-items-center justify-content-between flex-wrap px-md-3 px-2 border-bttom-opacity row-gap-3"
        >
          <div className="text-white my-md-3 my-2 px-4 m-0 text-center col-lg-3 col-md-3 col-6">
            <h5> سفر من {localTrip?.drop_location}</h5>
            <h6 className="text-uppercase f-eng">
              {trip?.start_time.replace(":00am", "  AM")}
            </h6>
          </div>
          <h5 className="bg-gold text-center rounded-pill py-2 px-3 m-0 f-eng col-lg-2 col-md-4 col-6">
            {trip?.type === "main" ? "Standard" : trip.type}
          </h5>
          <div className="text-center m-0 text-white col-lg-4 col-md-4 col-12">
            <h5 className="m-0"> الوصول الى</h5>
            <h5 className="m-0  text-uppercase">{localTrip?.pick_location}</h5>
          </div>

          <div
            onClick={() => {
              trip.id === chooseFinalTrip
                ? setChooseFinalTrip(true)
                : setChooseFinalTrip(() => trip.id);
            }}
            className="col-lg-2 col-12 text-decoration-none"
          >
            <h5
              className={`bg-gold text-center text-black rounded py-2 px-2 m-0 mb-lg-0 mb-2`}
              style={{ cursor: "pointer" }}
              onClick={() => localStorage.setItem("backId", trip.id)}
            >
              {chooseFinalTrip !== trip.id ? "اختر" : "إلغاء"}
            </h5>
          </div>
        </div>
      )
    );
  return (
    <>
      <div className="d-flex px-4 py-3 flex-wrap row-gap-2 shadow" dir="rtl">
        <div className="col-lg-2 col-md-3 col-6 d-md-block d-none">
          <h4>
            <img
              className="ms-2"
              width="20px"
              src={require("../../Assets/images/icon/maps-and-flags.png")}
              alt=""
            />
            سفر من
          </h4>
          <h6 className="d-flex align-items-center justify-content-start gap-2">
            <span>{localTrip?.pick_location}</span>
          </h6>
        </div>
        <div className="col-lg-2 col-md-3 col-6 d-md-block d-none">
          <h4>
            <img
              className="ms-2"
              width="20px"
              src={require("../../Assets/images/icon/maps-and-flags.png")}
              alt=""
            />
            الوصول
          </h4>
          <h6 className="d-flex align-items-center justify-content-start gap-2">
            <span>{localTrip?.drop_location}</span>
          </h6>
        </div>
        <div className="col-lg-2 col-md-3 col-6">
          <h4>
            <img
              className="ms-2"
              width="20px"
              src={require("../../Assets/images/icon/calendar (1).png")}
              alt=""
            />
            تاريخ السفر
          </h4>
          <h6 className="d-flex align-items-center justify-content-start gap-2">
            <span>
              {localTrip?.goDate &&
                new Date(localTrip.goDate).toLocaleDateString("ar-EG", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
            </span>
          </h6>
        </div>
        <div className="col-lg-2 col-md-3 col-6">
          <h4>
            <img
              className="ms-2"
              width="20px"
              src={require("../../Assets/images/icon/calendar (1).png")}
              alt=""
            />
            تاريخ العودة
          </h4>
          <h6 className="d-flex align-items-center justify-content-start gap-2">
            <span>
              {localTrip.backDate !== "no back date" &&
                new Date(localTrip.backDate).toLocaleDateString("ar-EG", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
            </span>
          </h6>
        </div>
        <div className="col-lg-2 col-md-3 col-6">
          <h4>
            <img
              className="ms-2"
              width="20px"
              src={require("../../Assets/images/icon/multiple-users-silhouette.png")}
              alt=""
            />
            عدد المسافرين
          </h4>
          <h6 className="d-flex align-items-center justify-content-start gap-2">
            <span>0</span>
          </h6>
        </div>
        <Link
          to="/"
          className="col-lg-2 col-md-3 text-center col-6 text-black text-decoration-none"
        >
          <Btn title="تعديل البحث" />
        </Link>
      </div>
      <div
        className="bg-url-without min-vh-100 d-flex justify-content-between px-lg-4 px-2 pt-5 position-relative flex-wrap row-gap-4"
        dir="rtl"
      >
        <div
          className="col-lg-8 col-12 custom-rounded bg-custom-primary-opacity trips"
          style={{ height: "70vh", overflowY: "scroll" }}
        >
          <div className="d-flex align-items-center justify-content-between ps-md-5 ps-0 border-bttom-opacity flex-wrap row-gap-2">
            <h4 className="bg-white py-4 px-4 rounded-3-sides m-0 col-md-3 col-12 text-md-start text-center">
              رحلة الذهاب
              <img
                style={{ transform: "rotate(180deg)" }}
                width="12px"
                className="me-3"
                src={require("../../Assets/images/icon/right-arrow.png")}
                alt=""
              />
            </h4>
            <div className="text-white text-center col-md-3 col-4 py-3">
              <h4 className="m-0">سفر من {localTrip?.pick_location}</h4>
            </div>
            <img
              width="30px"
              src={require("../../Assets/images/icon/front-of-bus.png")}
              alt=""
            />
            <div className="text-white text-center col-md-3 col-4">
              <h4 className="m-0">الوصول {localTrip?.drop_location} </h4>
            </div>
          </div>
          {/* Trips */}
          {tripsShow}
        </div>

        {/* Trips Back */}
        {localTrip.backDate !== "no back date" && chooseTrip !== true && (
          <div
            className="col-lg-8 col-12 custom-rounded bg-custom-primary-opacity trips mb-5"
            style={{ height: "70vh", overflowY: "scroll" }}
          >
            <div className="d-flex align-items-center justify-content-between ps-md-5 ps-0 border-bttom-opacity flex-wrap row-gap-2">
              <h4 className="bg-white py-4 px-4 rounded-3-sides m-0 col-md-3 col-12 text-md-start text-center">
                رحلة العودة
                <img
                  style={{ transform: "rotate(180deg)" }}
                  width="12px"
                  className="me-3"
                  src={require("../../Assets/images/icon/right-arrow.png")}
                  alt=""
                />
              </h4>
              <div className="text-white text-center col-md-3 col-4">
                <h4 className="m-0">سفر من {localTrip?.drop_location}</h4>
              </div>
              <img
                width="30px"
                src={require("../../Assets/images/icon/front-of-bus.png")}
                alt=""
              />
              <div className="text-white text-center col-md-3 col-4">
                <h4 className="m-0">الوصول {localTrip?.pick_location} </h4>
              </div>
            </div>
            {/* Trips */}
            {tripsBackShow}
          </div>
        )}
        <div className="col-lg-3 custom-rounded bg-custom-primary-opacity trips text-white px-4 py-3 trips-info ">
          <div className="position-relative">
            <div
              className=" position-absolute start-0 ps-4 arrow-bottom"
              onClick={() => setCollapseOpen((prev) => !prev)}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon
                className="fs-5"
                icon={faArrowRight}
                style={{
                  transform: collapseOpen ? "rotate(90deg)" : "rotate(-90deg)",
                  transition: "0.3s",
                }}
              />
            </div>
          </div>

          <div
            className={`${collapseOpen ? "open-collapse" : "close-collapse"}`}
            style={{ transition: "0.3s" }}
          >
            <h3 className="border-bttom-opacity pb-2">
              <span className="gold ms-2"> 0</span> مسافر
            </h3>
            <div className="py-2 border-bttom-opacity">
              <h3 className="text-center">
                {localTrip?.goDate &&
                  new Date(localTrip?.goDate).toLocaleDateString("ar-EG", {
                    weekday: "long",
                  })}{" "}
                {localTrip?.goDate &&
                  new Date(localTrip?.goDate).toLocaleDateString("ar-EG", {
                    month: "long",
                    year: "numeric",
                  })}
              </h3>
              <div className="d-flex align-items-center justify-content-center gap-3">
                <h5 className="text-center m-0">{localTrip?.pick_location}</h5>
                <h5 className="f-eng m-0">-</h5>
                <h5 className="text-center m-0">{localTrip?.drop_location}</h5>
              </div>
            </div>
            {localTrip.backDate !== "no back date" && (
              <div className="py-2 border-bttom-opacity">
                <h3 className="text-center">
                  {new Date(localTrip.backDate).toLocaleDateString("ar-EG", {
                    weekday: "long",
                  })}{" "}
                  {localTrip.backDate &&
                    new Date(localTrip.backDate).toLocaleDateString("ar-EG", {
                      month: "long",
                      year: "numeric",
                    })}
                </h3>
                <div className="d-flex align-items-center justify-content-center gap-3">
                  <h5 className="text-center m-0">
                    {localTrip?.drop_location}
                  </h5>
                  <h5 className="f-eng m-0">-</h5>
                  <h5 className="text-center m-0">
                    {localTrip?.pick_location}
                  </h5>
                </div>
              </div>
            )}
          </div>
          <h4 className="py-2 gold"></h4>
          <h3 className="py-2"></h3>
          {localTrip.backDate === "no back date"
            ? chooseTrip !== true && (
                <Link to="chairs" className="col-12 text-decoration-none">
                  <h5
                    to="chairs"
                    className={`bg-gold text-center text-black rounded py-2 px-2 m-0 mb-lg-0 mb-2`}
                    style={{ cursor: "pointer" }}
                  >
                    اختيار المقاعد
                  </h5>
                </Link>
              )
            : chooseFinalTrip !== true && (
                <Link to="chairs" className="col-12 text-decoration-none">
                  <h5
                    className={`bg-gold text-center text-black rounded py-2 px-2 m-0 mb-lg-0 mb-2`}
                    style={{ cursor: "pointer" }}
                  >
                    اختيار المقاعد
                  </h5>
                </Link>
              )}
        </div>
      </div>
      <Footer />
    </>
  );
}
