
export const FB_PIXEL_ID = '449890594276227';

export const initFacebookPixel = () => {
    if (window.fbq) return;
    window.fbq = function() {
        if (window.fbq.callMethod) {
            window.fbq.callMethod.apply(window.fbq, arguments);
        } else {
            window.fbq.queue.push(arguments);
        }
    };
    if (!window._fbq) window._fbq = window.fbq;
    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = '2.0';
    window.fbq.queue = [];
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://connect.facebook.net/en_US/fbevents.js`;
    document.head.appendChild(script);
    window.fbq('init', FB_PIXEL_ID);
    window.fbq('track', 'PageView');
};

export const trackEvent = (eventName, params = {}) => {
    window.fbq('track', eventName, params);
};
