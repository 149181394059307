import { useEffect, useState } from "react";
import { Axios } from "../../Api/axios";
import { FAQQuestions } from "../../Api/Api";
import parse from "html-react-parser";
import { Accordion, Container } from "react-bootstrap";
import SecondHeader from "../../Components/Header/SecondHeader";
import Footer from "../../Components/Footer/Footer";
import "./faq.css";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";
import { Helmet } from "react-helmet";

export default function Faq() {
  const [faqQuestion, setFaqQuestion] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState(true);

  useEffect(() => {
    Axios.get(`/${FAQQuestions}`)
      .then((data) => {
        const res = data.data.data;
        setFaqQuestion(res);
      })
      .finally(() => setLoadingSpinner(false));
  }, []);

  const showQuestion = faqQuestion.map((item, i) => (
    <div dir="rtl">
      <Accordion defaultActiveKey="0">
        <Accordion.Item
          className="bg-gray mb-5 p-md-4 p-3 w-100 rounded shadow"
          eventKey="0"
        >
          <Accordion.Header className="bg-gray h1">
            {item.question}
          </Accordion.Header>
          <Accordion.Body>{parse(item.description)}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  ));

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>الأسئلة الشائعة - أمواج</title>
      </Helmet>
      <SecondHeader />
      {loadingSpinner && <LoadingSpinner />}
      <Container className="fs-3 mt-5">
        <div>{showQuestion}</div>
      </Container>
      <Footer />
    </div>
  );
}
