import "./CircleLoading.css";

export default function CircleLoading() {
  return (
    <div className="position-relative">
      <div id="loading-bar-spinner" className="spinner">
        <div className="spinner-icon"></div>
      </div>
    </div>
  );
}
