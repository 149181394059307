import { useContext, useEffect, useState } from "react";
import "./PaymentBtn.css";

import { Axios } from "../../Api/axios";
import { Pay } from "../../Api/Api";
import { useNavigate } from "react-router-dom";
import { Ticket } from "../../Context/TicketContext";
export default function PaymentBtn() {
  // States
  const [payment, setPayment] = useState("");

  const [lastPrice, setLastPrice] = useState("");
  const GetTicket = useContext(Ticket);
  // Nav
  const nav = useNavigate();

  // Get Data
  useEffect(() => {
    const tickets = localStorage.getItem("tickets");
    setLastPrice(GetTicket.ticket.price);
    const script = document.createElement("script");
    script.src = "https://www.simplify.com/commerce/simplify.pay.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.SimplifyCommerce.hostedPayments((res) => {
        const cardToken = res.cardToken;
        const card = res;
        async function HandleSend() {
          await Axios.post(`/${Pay}`, {
            payment_method: 4,
            tickets: tickets,
            cardToken: cardToken,
            paymentData: card,
          });
          nav("/profile");
        }
        HandleSend();
      }).closeOnCompletion();
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  console.log(payment);
  return (
    <iframe
      name="my-hosted-form"
      data-sc-key="lvpb_MGE2YjBmZWUtZjc0OC00MmZiLTg5OWYtNGMxZTgyOTNhZWY2"
      data-name="شركة أمواج للنقل البري"
      data-description="أمواج شريك تثق فيه."
      data-reference="Online V1"
      data-amount={lastPrice * 100}
      data-color="#12B830"
      data-operation="create.token"
    ></iframe>
  );
}
