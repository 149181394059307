import { Route, Routes } from "react-router-dom";
import Login from "./Website/Auth/Login";
import Register from "./Website/Auth/Register";
import HomePage from "./Website/Home/Home";
import Payment from "./Website/Payment/Payment";
import Trips from "./Website/Trips/Trips";
import Chairs from "./Website/Chairs/Chairs";
import Profile from "./Website/Profile/Profile";
import Policy from "./Website/Policy/Policy";
import Terms from "./Website/Terms/Terms";
import About from "./Website/About/About";
import Faq from "./Website/FAQ/Faq";
import Contact from "./Website/Contact/Contact";

import RequireAuth from "./Website/Auth/RequireAuth";
import EditProfile from "./Website/Profile/EditProfile";
import EditPassword from "./Website/Profile/EditPassword";
import ForgetPassword from "./Website/Auth/ForgetPassword";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import RequireLocal from "./Website/Auth/RequireLocal";
import Page404 from "./Website/404/404";
import PaymentRedirect from "./Website/Payment/PaymentRedirect";

import PaymentMobileRedirect from "./Website/Payment/PaymentMobileRedirect";

export default function App() {
  return (
    <>
      <ScrollToTop />

      <Routes>
        {/* Public Routes */}

        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/register" element={<Register />} />

        <Route path="/policy" element={<Policy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/" element={<HomePage />} />
        {/* Protected Routes */}

        <Route element={<RequireAuth />}>
          <Route element={<RequireLocal />}>
            <Route path="/trips" element={<Trips />} />
            <Route path="/trips/chairs" element={<Chairs />} />
          </Route>
          <Route path="/payment" element={<Payment />} />
          <Route path="/paymob" element={<PaymentRedirect />} />
          <Route path="/paymobmobile" element={<PaymentMobileRedirect />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/edit" element={<EditProfile />} />
          <Route path="/profile/edit-password" element={<EditPassword />} />
        </Route>

        {/* 404 Page */}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
  );
}
